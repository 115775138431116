import { MarketsMessage } from "./markets";
import { MessageTopics } from "./messageTopics";

export { MessageTopics };

export interface IframeUrlObject {
  origin: string;
  path: string;
}

export interface ConstructorArgs {
  //? Operator.
  operator?: string;
  //? Indicates the theme to use
  theme?: string;
  //? Indicates the language to use .
  language?: string;
  //? selector for element where the Iframe is going to be added
  targetElementSelector: string;
  //? Indicates the backend environment for the event centre to use.
  env?: "dev" | "staging" | "sims" | "prod" | "demo" | string;
  //? when enabled the module will print debug messages
  __debug__?: boolean;
}

export interface EventCentreConstructorArgs extends ConstructorArgs {
  sport: string;
  //? Indicates the version of the event centre to use.
  version?: "latest" | string;
  //? Operator.
  operator: string;
  //? the environment of the consumer (e.g. UAT, or Staging; one use-case is that it allows us to treat analytics differently)
  operatorEnv?: string;
  //? Indicates the version of Betlink ot use, setting this will show the Betlink iframe instead of the event centre.
  betlinkVersion?: string;
  options?: InitializationOptions;
  targetModule?: string;
  initialContext?: ContextUpdateEvents;
  //? Indicates the Betlink backend environment to use, overrides env
  betlinkEnv?: "dev" | "staging" | "sims" | "prod" | "demo" | string;
  enableEventSelection?: boolean;
  trading?: boolean;
  //? Used to target a specific event for Betlink or the event centre.
  eventId?: EventIdType | string;
  //? Overrides the URL of the event centre iframe embedded within Betlink
  __iframe_url_override__?: IframeUrlObject;
  //? Overrides the URL of the iframe that loads the event centre or Betlink
  __betlink_iframe_url_override__?: IframeUrlObject;
  __replay_id__?: string;
}

export interface EventCentreLiteConstructorArgs extends ConstructorArgs {
  //? sport to be loaded in the event centre lite
  sport: string;
  //? name of the standalone module to be loaded
  targetModule?: string;
  //? name of the page to preload
  view?: "Stream" | "Pitch" | "Lineups" | "Stats";
  //? Initial context
  initialContext?: {
    //? page to be displayed.
    page?: number;
  };
  //? Used to target a specific event
  eventId: string;
  //? this is a private property to override the URL
  __iframe_url_override__?: {
    //? Overrides the origin in the url
    origin?: string;
  };
  //? Overrides the URL of the iframe that loads the event centre or Betlink
  __replay_id__?: string;
}

export const enum HandshakeStates {
  Pending,
  Failed,
  Success,
}

export interface ShowEventCentreMessage {
  eventId: string;
}

export interface HandshakeMessage {
  handshakeRef: string;
  frameOrigin?: string;
  versionNumber?: string;
}

export interface HandshakeFailed {
  timestamp: number;
}

export interface QueuedMessage {
  topic: MessageTopics;
  context: IntegrationEvent;
}

export interface ContextUpdateEventBase {
  view: string;
}

export type GolfViews =
  | "leaderboard"
  | "player-detail"
  | "player-detail-hole"
  | "player-detail-scorecard"
  | "player-detail-shots"
  | "player-detail-stats"
  | "player-detail-streaming"
  | "playoff-detail" // compatibility: same as "playoff-detail-hole"
  | "playoff-detail-hole"
  | "playoff-detail-shots"
  | "groups"
  | "groupdetail"
  | "group-detail"
  | "group-detail-hole"
  | "group-detail-scorecard"
  | "group-detail-shots"
  | "group-detail-stats"
  | "group-detail-streaming"
  | "course"
  | "course-live-streams"
  | "course-hole-detail" // compatibility: same as "course-detail-hole"
  | "course-detail-hole"
  | "course-detail-scorecard"
  | "course-detail-shots"
  | "course-detail-streaming"
  | "match-play-list"
  | "match-play-detail" // compatibility: same as "match-play-detail-hole"
  | "match-play-detail-hole"
  | "match-play-detail-scorecard"
  | "match-play-detail-shots"
  | "match-play-shot-tracker"
  | "match-play-streaming"
  | "match-play-streaming-hole"
  | "lpga-streaming"
  | "streaming"
  | "hospitality-detail-hole"
  | "the-open-player-tracker"
  | "the-open-hole-tracker"
  | "stats"
  | "insights";

export interface GolfContextUpdateEvent extends ContextUpdateEventBase {
  view: GolfViews;
  tournamentId: number | string;
  roundNo?: number | string;
  holeNo?: number | string;
  teamNo?: number | string;
  groupNo?: number | string;
  groupId?: number | string;
  courseId?: number | string;
  matchNo?: number | string;
  playerId?: number | string;
  leagueId?: number | string; // specific for Draft Kings and the golf hospitality target module
  roundStatuses?: Array<{ number?: number; status?: string }>;
}

interface MWSoccerContextUpdateBase extends ContextUpdateEventBase {
  seasonId: string;
  fixtureId?: string;
  teamId?: string;
  simpleView?: boolean;
}

interface MWSoccerPassesContextUpdate extends MWSoccerContextUpdateBase {
  view: "passes";
  playerId?: string;
}

interface MWSoccerXGContextUpdate extends MWSoccerContextUpdateBase {
  view: "xg";
}

export type MWSoccerContextUpdate =
  | MWSoccerPassesContextUpdate
  | MWSoccerXGContextUpdate;

export interface UfcContextUpdateEvent extends ContextUpdateEventBase {
  fightId?: number | string;
}

export enum TennisRoundShortNames {
  Bronze = "Bronze",
  F = "F",
  First = "First",
  Fourth = "Fourth",
  Q = "Q",
  Q1 = "Q1",
  Q2 = "Q2",
  Q3 = "Q3",
  Q4 = "Q4",
  Qf = "QF",
  Robin = "Robin",
  Rubber = "Rubber",
  Sf = "SF",
  Second = "Second",
  Third = "Third",
  W = "W",
}

export enum MatchType {
  Ld = "LD",
  Ls = "LS",
  Md = "MD",
  Ms = "MS",
  Qld = "QLD",
  Qls = "QLS",
  Qmd = "QMD",
  Qms = "QMS",
  Qs = "QS",
  Qxd = "QXD",
  Rs = "RS",
  Xd = "XD",
}

export interface TennisContextUpdateEvent extends ContextUpdateEventBase {
  matchId?: string;
  round?: TennisRoundShortNames;
  matchType?: MatchType;
}

export type SoccerView =
  | "teams"
  | "teams-lineups"
  | "teams-formations"
  | "live"
  | "stats"
  | "stats-player"
  | "stats-match"
  | "form"
  | "form-guide"
  | "form-teams"
  | "form-leaders"
  | "form-standings"
  | "form-players"
  | "form-players-goals"
  | "form-players-shots"
  | "form-players-cards"
  | "chalkboard";

export interface SoccerContextUpdateEvent extends ContextUpdateEventBase {
  view: SoccerView;
  playerShirtNumber?: string;
  team?: "home" | "away";
  chalkboard?: boolean;
}

export interface BasketballContextUpdateEvent extends ContextUpdateEventBase {
  eventId?: string;
  operatorId?: number | string;
  seasonId?: string;
  federationId?: string;
  teamId?: string;
}

export type ContextUpdateEvents =
  | GolfContextUpdateEvent
  | TennisContextUpdateEvent
  | UfcContextUpdateEvent
  | BasketballContextUpdateEvent
  | MWSoccerContextUpdate
  | SoccerContextUpdateEvent;

export interface StakeLimits {
  maxStake: number;
  minStake: number;
  maxPayOut: number;
  minPayOut: number;
}

export interface BetlinkInitializationOptions {
  fractionalOdds?: boolean;
  allowDecimalStakes?: boolean;
  allowDecimalPayouts?: boolean;
  currencySymbol?: string;
  quickStakes?: { [key: string]: number };
  stakeLimits?: StakeLimits;
  eachWay?: boolean;
  multiplesBetting?: boolean;
  closeButton?: boolean;
}

export interface InitializationOptions {
  videoPlaybackEnabled: boolean;
  userId?: string;
  units?: "imperial" | "metric";
  betlinkOptions?: BetlinkInitializationOptions;
  disablePeopleImages?: boolean;
  holeNavOnly?: boolean; // used to amend UI for 'course-live-streams' view
  oddsFormat?: "fractional" | "decimal" | "moneyline";
}

export interface VideoPlaybackAuthResponseEvent {
  operatorId: string;
  auth: string;
  timestamp: number;
  error?: Error;
}

export interface BetlinkAuthTokenResponseEvent {
  operatorAuthToken: string;
  userId: string;
  operatorId: string;
}

export interface SelectionUpdateEvent {
  marketId: number | string;
  betId: number | string;
  selected: boolean;
}

export interface CurrentEventIdUpdate {
  eventId: string;
}

export type IntegrationEvent =
  | HandshakeMessage
  | HandshakeFailed
  | ShowEventCentreMessage
  | SelectionUpdateEvent
  | GolfContextUpdateEvent
  | UfcContextUpdateEvent
  | TennisContextUpdateEvent
  | InitializationOptions
  | VideoPlaybackAuthResponseEvent
  | BetlinkAuthTokenResponseEvent
  | CurrentEventIdUpdate
  | MarketsMessage
  | Record<string | number, unknown>;

export interface InternalMessage {
  topic: MessageTopics;
  context: IntegrationEvent;
  handshakeRef: string;
}

export interface IntegrationEventListener {
  (event: IntegrationEvent): void;
}

export interface CreateSelectionUpdateEventProps {
  marketId: number | string;
  betId: number | string;
  selected: boolean;
}

export enum EventIdType {
  Current = "current",
}

export enum SportType {
  Golf = "golf",
  Tennis = "tennis",
  Ufc = "ufc",
  Basketball = "basketball",
  Soccer = "soccer",
  MwSoccer = "mw-soccer",
}
