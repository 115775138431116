import { MessageTopics } from "./messageTopics";

export interface Market {
  id: string;
  options: MarketOption[];
  title: string;
  suspended?: boolean;
}

export interface MarketOption {
  id: string;
  playerId: string;
  title: string;
  odds: MarketOdds;
  suspended?: boolean;
  marketDirection?: MarketDirection;
}

export interface MarketOdds {
  decimal: string;
  fractional: string;
  american: string;
}

export enum MarketDirection {
  DEFAULT = "default",
  LENGTHENING = "lengthening",
  SHORTENING = "shortening",
}

export interface MarketsUpdateMessage {
  markets: Market[];
}

export interface MarketsSuspendMessage {
  marketIds: string[];
}

export interface MarketsRemoveMessage {
  marketIds: string[];
}

export interface MarketsPlaceBetMessage {
  market: Market;
  option: MarketOption;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MARKETS_EVENT = {
  UPDATE: MessageTopics.MARKETS_UPDATE,
  SUSPEND: MessageTopics.MARKETS_SUSPEND,
  REMOVE: MessageTopics.MARKETS_REMOVE,
  PLACE_BET: MessageTopics.MARKETS_PLACE_BET,
} as const;

export type MarketsEvent = (typeof MARKETS_EVENT)[keyof typeof MARKETS_EVENT];

export type MarketsMessage =
  | MarketsUpdateMessage
  | MarketsSuspendMessage
  | MarketsRemoveMessage
  | MarketsPlaceBetMessage;
